<div
  class="sm:rounded-[12px] sm:bg-white border-t sm:border border-t-G200 sm:border-G200 py-[16px] sm:p-[24px] h-max font-sans flex flex-col gap-[16px] sm:gap-[24px] md:sticky md:top-[75px]"
>
  <div class="flex justify-between items-center gap-[8px]">
    <status-icon [job]="job" />

    <ng-container *ngIf="isPostJobPage">
      <share-button [link]="'https://canwork.io/jobs/public/' + job.slug" />
    </ng-container>

    <ng-container *ngIf="!isPostJobPage">
      <div class="flex gap-[8px]" *ngIf="isAcceptingOffers">
        <bookmark-button />
        <share-button [link]="'https://canwork.io/jobs/public/' + job.slug" />
      </div>
    </ng-container>
  </div>
  <div class="flex flex-col gap-[16px]">
    <div class="flex justify-between items-center">
      <div class="text-b2 text-G700 capitalize">{{ job.paymentType }}</div>
      <div class="text-b1 text-G900 font-medium">
        ${{ job.budget | number : '1.0-0' }}
        <span *ngIf="job.paymentType !== 'Fixed price'">/hr</span> USD
      </div>
    </div>
    <div *ngIf="isAcceptingOffers" class="flex justify-between items-center">
      <div class="text-b2 text-G700 capitalize">Proposals</div>
      <div class="text-b1 text-G900 font-medium">{{ proposals }} Proposals</div>
    </div>
    <div class="flex justify-between items-center">
      <div class="text-b2 text-G700 capitalize">Deadline</div>
      <div class="text-b1 text-G900 font-medium">
        {{ formatDateFromString(job.deadline) }}
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div class="text-b2 text-G700 capitalize">Estimated hours</div>
      <div class="text-b1 text-G900 font-medium">
        {{ job.information.weeklyCommitment }} Hours
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div class="text-b2 text-G700 capitalize">Category</div>
      <div class="text-b1 text-G900 font-medium">
        {{ getCategoryName(job.information.providerType) }}
      </div>
    </div>
  </div>
  <hr
    *ngIf="isPostJobPage || (!isPostJobPage && jobPoster)"
    class="w-full h-[1px] bg-G300 border-none"
  />
  <div
    *ngIf="isPostJobPage || (!isPostJobPage && jobPoster)"
    class="flex flex-col gap-[16px]"
  >
    <div
      *ngIf="isPostJobPage || (!isPostJobPage && jobPoster)"
      class="text-b2 text-G700"
    >
      About the Client
    </div>
    <profile-card
      *ngIf="isPostJobPage"
      [avatarUri]="currentUser.avatar['uri']"
      [name]="currentUser.name"
      [title]="currentUser.title"
      [isVerified]="currentUser.verified"
      [rating]="currentUser.rating"
      [skillTags]="currentUser.skillTags"
      [slug]="currentUser.slug"
      [address]="currentUser.address"
      [timezone]="currentUser.timezone"
      [hourlyRate]="currentUser.hourlyRate"
      [isGrid]="false"
      [isSmall]="true"
    />
    <profile-card
      *ngIf="!isPostJobPage && jobPoster"
      [avatarUri]="jobPoster.avatarUri"
      [name]="jobPoster.name"
      [title]="jobPoster.title"
      [isVerified]="jobPoster.verified"
      [rating]="jobPoster.rating"
      [skillTags]="jobPoster.skillTags"
      [slug]="jobPoster.slug"
      [address]="jobPoster.address"
      [timezone]="jobPoster.timezone"
      [hourlyRate]="jobPoster.hourlyRate"
      [isGrid]="false"
      [isSmall]="true"
    />
    <!-- *ngIf="!selectedBid.rejected" -->
    <ng-container *ngIf="!isPostJobPage">
      <div
        *ngIf="!isMyJob && !isAcceptingOffers && jobPoster"
        class="flex pl-[80px] gap-[16px] items-center"
      >
        <chat-button
          [routerLink]="['/inbox/chat']"
          [queryParams]="{ address: jobPoster.address }"
        />
        <basic-button
          [type]="1"
          title="View Profile"
          size="small"
          extraClass="border border-G200"
          [routerLink]="['/profile/', jobPoster.slug]"
        />
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="isPostJobPage">
    <div
      *ngIf="isPostButtonsShow"
      class="flex flex-col sm:flex-row sm:justify-between gap-4 items-center"
    >
      <back-to-edit-button (click)="leftClick($event)" />
      <basic-button
        [type]="2"
        title="Post Job"
        size="small"
        (click)="rightClick($event)"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="!isPostJobPage">
    <ng-container *ngIf="currentUser && isMyJob">
      <ng-container *ngIf="isAcceptingOffers">
        <div class="flex flex-row gap-4 items-center">
          <div
            class="cursor-pointer select-none py-[10px] px-[24px] text-R900 flex justify-center items-center gap-2 font-medium text-b1 text-nowrap hover:bg-G200 rounded-xl transition-all duration-300"
            (click)="leftClick($event)"
          >
            <img
              src="/assets/massimo/svg/delete.svg"
              class="select-none w-[16px] h-[16px]"
            />
            Delete Job
          </div>

          <div class="w-max">
            <basic-button
              [type]="1"
              title="Edit Job"
              [routerLink]="['/inbox/job/edit', job.id]"
            />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isAwaitingEscrow">
        <div class="flex gap-4 items-center">
          <div
            class="cursor-pointer select-none py-[10px] px-[24px] text-R900 flex justify-center items-center font-medium text-b1 text-nowrap hover:bg-G200 rounded-xl transition-all duration-300"
            (click)="leftClick($event)"
          >
            Cancel Job
          </div>

          <div class="w-max">
            <basic-button
              [type]="2"
              size="small"
              extraClass="!text-b1"
              title="Pay Escrow"
              (click)="rightClick($event)"
            />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isInEscrow">
        <div class="flex flex-col sm:flex-row gap-4 items-center">
          <raise-dispute-button (click)="leftClick($event)" />

          <basic-button
            [type]="2"
            title="Add Note"
            (click)="rightClick($event)"
            extraClass="w-max"
            size="small"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="isMarkedAsComplete">
        <div class="flex flex-col gap-4 items-center">
          <raise-dispute-button (click)="leftClick($event)" />
          <basic-button
            [type]="2"
            title="Release Payment"
            extraClass="w-max"
            size="small"
            (click)="rightClick($event)"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="isJobComplete">
        <div class="flex justify-center">
          <basic-button
            [type]="2"
            title="Leave a review"
            extraClass="w-max"
            size="small"
            (click)="leftClick($event)"
            [disabled]="!userCanReview"
          />
        </div>
      </ng-container>
    </ng-container>
    <!-- *ngIf="currentUser && !isMyJob && (isAwaitingEscrow || isInEscrow)" -->
    <ng-container *ngIf="currentUser && !isMyJob">
      <!-- <ng-container *ngIf="isAwaitingEscrow">
          <div
            class="cursor-pointer select-none py-[10px] px-[24px] text-R900 flex justify-center items-center font-medium text-b1 text-nowrap hover:bg-G200 rounded-xl transition-all duration-300"
            (click)="leftClick($event)"
          >
            Cancel Job Early
          </div>

          <basic-button
            [type]="2"
            title="Add Note"
            (click)="rightClick($event)"
            extraClass="w-max"
            size="small"
          />
        </ng-container> -->
      <ng-container *ngIf="isInEscrow">
        <div
          class="flex max-sm:flex-col lg:flex-col gap-4 justify-center items-center"
        >
          <button
            class="cursor-pointer select-none py-[10px] px-[24px] text-R900 flex justify-center items-center font-medium text-b1 text-nowrap hover:bg-G200 rounded-xl transition-all duration-300"
            (click)="leftClick($event)"
            [disabled]="!job.bscEscrow"
          >
            Cancel Job Early
          </button>
          <basic-button
            [type]="2"
            title="Mark as complete"
            (click)="rightClick($event)"
            extraClass="w-max"
            size="small"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="isMarkedAsComplete">
        <div class="flex flex-col gap-4 items-center">
          <raise-dispute-button (click)="leftClick($event)" />
          <basic-button
            [type]="2"
            title="Mark as complete"
            extraClass="w-max"
            size="small"
            [disabled]="true"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="isJobComplete">
        <div class="flex justify-center">
          <basic-button
            [type]="2"
            title="Leave a review"
            extraClass="w-max"
            size="small"
            (click)="leftClick($event)"
            [disabled]="!userCanReview"
          />
        </div>
      </ng-container>
    </ng-container>

    <div
      *ngIf="!currentUser && !isMyJob && isAcceptingOffers"
      (click)="leftClick($event)"
      class="w-full"
    >
      <basic-button [type]="2" title="Login to Apply Now" class="w-full" />
    </div>
  </ng-container>
</div>
